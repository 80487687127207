<template>
  <Home/>
</template>


<script>
// @ is an alias to /src

import Home from '@/components/Home.vue'

export default {
  name: 'HomeView',
  components: {
    Home
  }
}
</script>
