<template>
  <v-app>
<!--    <v-app-bar
      app
      color="primary"
      dark
    >
    </v-app-bar>-->
<!--
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
-->

    <v-main>
      <router-view/>
    </v-main>
    <v-footer padless>
      <v-col
          class="text-center"
          cols="12"
      >
        &copy; {{ new Date().getFullYear() }} — <strong>Massage For You</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
// import Home from './components/Home';

export default {
  name: 'App',

  components: {
    // Home,
  },

  data: () => ({
    //
  })
};
</script>
<style lang="scss">
@import "./assets/styles/app.scss";
</style>
